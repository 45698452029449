import React from 'react'
import Layout from '../../components/layout/layout'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import InputGroup from 'react-bootstrap/InputGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'

import EnergyStarLogo from '../../images/logos/energy_star_logo.svg'


const CarbonCalculator = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Home" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Carbon Calculator'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card className="cardBodyBackground mb-4">
                <div style={{margin: `2rem`}}>
                  <Tabs id="" className="mb-3">
                    <Tab eventKey="get-started" title="Get Started" >
                      <h1 className="mb-4">Carbon Footprint Calculator: What's My Carbon Footprint?</h1>
                      <p>
                        Inevitably, in going about our daily lives&mdash;commuting, sheltering our families, eating&mdash;each of us contributes to the greenhouse gas emissions that are causing climate change. Yet, <strong>there are many things each of us, as individuals, can do to reduce our carbon emissions.</strong> The choices we make in our homes, our travel, the food we eat, and what we buy and throw away all influence our carbon footprint and can help ensure a stable climate for future generations.
                      </p>

                      <p>
                        Use The Nature Conservancy's <strong>carbon footprint calculator</strong> to measure your impact on our climate. Our carbon footprint calculator estimates how many tons of carbon dioxide and other greenhouse gases your choices create each year.
                      </p>

                      <p>
                        Carbon dioxide (CO<sub>2</sub>) is the primary greenhouse gas in our atmosphere. This means that the amount of carbon dioxide in our atmosphere directly influences how much of the sun's heat stays in the atmosphere, which is a key factor in climate change. One ton of carbon dioxide is released when you travel 5,000 miles in an airplane, drive 2,500 miles in a medium-sized car, or cut down and burn a tree that was about one foot in diameter and 40 feet tall. There are other greenhouse gases as well, such as methane and nitrous oxide. This carbon footprint calculator includes these gases in your results and reports them in a standardized measure called "tons of CO<sub>2</sub> equivalent."
                      </p>

                      <p>
                        <strong>To get started</strong>, select the number of people in your home. Then choose whether you want to calculate a carbon footprint for yourself or for your household.
                      </p>

                      <p>
                        <strong>There are [BUTTON] people in my household.
                        </strong>
                      </p>

                      <Row>
                        <Col xs={2} className="mb-4">
                          <select className="form-control">
                            <option>Select</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10+</option>
                          </select>
                        </Col>
                        <Col xs={2}>
                          <InputGroup className="mb-3" style={{position: `relative`, height: `overflow-y: scroll`}}>
                            <DropdownButton
                              as={ InputGroup.Prepend }
                              title="Select"
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item href="#">1</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">2</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">3</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">4</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">5</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">6</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">7</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">8</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">9</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">10+</Dropdown.Item>
                            </DropdownButton>
                          </InputGroup>
                        </Col>
                      </Row>

                      <ButtonToolbar>
                        <Button size="lg" style={{marginRight: `1.5rem`}}>Calculate for me only</Button>
                        <Button size="lg">Calculate for my household</Button>
                      </ButtonToolbar>
                    </Tab>

                    <Tab eventKey="home-energy" title="Home Energy">
                      <h1 className="mb-4">Home Energy Use</h1>
                      <p>I live in a(n) [BUTTON] with [BUTTON] bedrooms</p>

                      <Row>
                        <Col xs={3} className="mb-4">
                          <select className="form-control">
                            <option>Select household type</option>
                            <option>Single family home (detached)</option>
                            <option>Single family home (attached)</option>
                            <option>Apartment building (2 to 4 units)</option>
                            <option>Apartment building (5+ unit)</option>
                            <option>Mobile home</option>
                          </select>
                        </Col>
                        <Col>
                          <InputGroup className="mb-3">
                            <DropdownButton
                              as={ InputGroup.Prepend }
                              title="Select household type"
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item href="#">Single family home (detached)</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">Single family home (attached)</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">Apartment building (2 to 4 units)</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">Apartment building (5+ unit)</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">Mobile home</Dropdown.Item>
                            </DropdownButton>
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={3} className="mb-4">
                          <select className="form-control">
                            <option>Select number of bedrooms</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5+</option>
                          </select>
                        </Col>
                        <Col>
                          <InputGroup className="mb-3">
                            <DropdownButton
                              as={ InputGroup.Prepend }
                              title="Select number of bedrooms"
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item href="#">1</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">2</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">3</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">4</Dropdown.Item>
                                <Dropdown.Divider />
                              <Dropdown.Item href="#">5+</Dropdown.Item>
                            </DropdownButton>
                          </InputGroup>
                        </Col>
                      </Row>

                      <p>I live in [BUTTON].</p>

                      <Row>
                        <Col xs={2} className="mb-4">
                          <select className="form-control">
                            <option>Select state</option>
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Delaware</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option>
                            <option>New Jersey</option>
                            <option>New Mexico</option>
                            <option>New York</option>
                            <option>North Carolina</option>
                            <option>North Dakota</option>
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option>
                            <option>South Carolina</option>
                            <option>South Dakota</option>
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option>
                            <option>Wisconsin</option>
                            <option>Wyoming</option>
                          </select>
                        </Col>
                        <Col>
                          <InputGroup className="mb-3">
                            <DropdownButton
                              as={ InputGroup.Prepend }
                              title="Select state"
                              id="input-group-dropdown-1"
                            >
                              <Dropdown.Item href="#">&hellip;</Dropdown.Item>
                                <Dropdown.Divider />
                            </DropdownButton>
                          </InputGroup>
                        </Col>
                      </Row>

                      <p className="lead">Your Estimated Impact (based on U.S. state averages)</p>

                      <p className="lead">What Have You Done to Change Your Impact?</p>

                      <p>I've taken steps to heat and cool my home efficiently.</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Wherever possible" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="In some areas" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Very little" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>I have installed efficient lighting.</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Everywhere possible" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="In some lights" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Not yet" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>I use ENERGY STAR<sup>&reg;</sup> appliances and electronics and unplugging equipment not in use.</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Always" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Sometimes" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Rarely" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>I have taken steps to reduce energy used for hot water.</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="As much as possible" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Somewhat" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Not yet" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>"Attached" single family homes includes townhouses, duplexes, and some condos. Condos in large multi-residence buildings should be treated as apartments. The number of bedrooms allows us to estimate the size of the dwelling.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 1</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Some states generate cleaner electricity than others, which affects your home energy impact.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 2</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Some possible steps include:</p>
                                <ul style={{textAlign: `left`, margin: `0.5rem`, paddingLeft: `0.5rem`}}>
                                  <li>Install a programmable thermostat</li>
                                  <li>Set the temperature lower for heating and higher for cooling</li>
                                  <li>Reduce drafts from windows and doors</li>
                                  <li>Properly insulate your attic and walls</li>
                                  <li>Use ceiling fans and floor fans for cooling</li>
                                  <li>Shade windows using drapes or overhangs</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 3</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Fluorescent lighting is far more efficient than halogen and standard incandescent lights. Compact fluorescents (CFLs) are a simple replacement of standard incandescent and they consume only 1/3 the energy and last about 10 times as long as standard incandescent lights. For this question you should indicate how much of your lighting is fluorescent.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 4</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>ENERGY STAR<sup>&reg;</sup> equipment and appliances are labeled with this blue logo.</p>
                                <img
                                  style={{width: `120px`, height: `auto`,}}
                                  src={ EnergyStarLogo }
                                  alt="Energy star logo"
                                />
                                <ul style={{textAlign: `left`, margin: `0.5rem`, paddingLeft: `0.5rem`}}>
                                  <li>If you know that you buy ENERGY STAR<sup>&reg;</sup> labeled products, you should select "Sometimes" or "Always".</li>
                                  <li>If you rarely purchase new appliances or equipment but you unplug electronic devices not in use, you should select "Sometimes", since you are still saving energy.</li>
                                  <li>If you are not familiar with this program or don't consider energy efficiency when buying equipment and household appliances you should select "Rarely".</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 5</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Some possible steps include:</p>
                                <ul style={{textAlign: `left`, margin: `0.5rem`, paddingLeft: `0.5rem`}}>
                                  <li>Install low-flow shower heads</li>
                                  <li>Turn the hot water temperature down to 120&deg;F</li>
                                  <li>Properly insulate your hot water tank</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 6</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar>
                        <Button size="lg" style={{marginRight: `1.5rem`, float: `right`}}>Continue</Button>
                      </ButtonToolbar>
                    </Tab>

                    <Tab id="driving-flying" eventKey="driving-flying" title="Driving &amp; Flying">
                      <h1 className="mb-4">Driving &amp; Flying</h1>
                      <p className="lead">What Have You Done to Change Your Impact?</p>

                      <p>
                        We drive the following vehicle(s): <br />
                        [BUTTON] roughly [INPUT] miles per [BUTTON]
                      </p>
                      <Col xs={3}>
                        <select className="form-control">
                          <option>Select vehicle type</option>
                          <option>No vehicle</option>
                          <option>Hybrid (or above 40 mpg)</option>
                          <option>Small (or 30-40 mpg)</option>
                          <option>Mid-size (or 20-30 mpg)</option>
                          <option>Large (or less than 20 mpg)</option>
                        </select>
                      </Col>

                      <p>[ADD A VEHICLE BUTTON]</p>

                      <Form className="mb-3">
                        <Col xs={3}>
                          <Form.Control placeholder="Number of miles" />
                        </Col>
                      </Form>

                      <Col xs={2} className="mb-3">
                        <select className="form-control">
                          <option>Select</option>
                          <option>Year</option>
                          <option>Month</option>
                          <option>Week</option>
                          <option>Day</option>
                        </select>
                      </Col>

                      <p>We generally check the air filter &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Monthly" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Occasionally" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Rarely" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>We check the tire pressure &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Monthly" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Occasionally" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Rarely" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>
                        The total number of flights we have flown in the last year, counting each flight taken by each member in my household, is: <br />
                        [INPUT] long flights and [INPUT] short flights.
                      </p>
                      <Form className="mb-3">
                        <Row>
                          <Col xs={3}>
                            <Form.Control placeholder="Number of flights" />
                          </Col>
                          <Col xs={3}>
                            <Form.Control placeholder="Number of flights" />
                          </Col>
                        </Row>
                      </Form>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>If your vehicle is not represented in our list of common vehicle types, please use the most appropriate type based on miles per gallon (mpg) fuel consumption. "Hybrid" refers to vehicles like the Toyota Prius that use a hybrid gas/electric power system. The type of vehicle you own and especially the number of miles you drive have a significant impact on your overall greenhouse gas emissions. If you have multiple vehicles, click on the 'add vehicle' link.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 1</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Air filters are often checked as part of a standard oil change service. The more miles you drive, the more impact checking your air filter has.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 2</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>Since tires slowly lose air over time and with changes in temperature, it is best to check them or have them checked periodically. Frequent drivers will see more impact from this behavior than those with less miles driven.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 3</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <p style={{textAlign: `left`, margin: `0.5rem`}}>All flights are round trips per person and should include both business and personal travel. If two people fly on the same trip, it is considered two trips. Consider a short flight to be less than 1.5 hours (300 miles) one way.</p>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 4</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar>
                        <Button size="lg" style={{position: `relative`, float: `right`}}>Continue</Button>
                      </ButtonToolbar>
                    </Tab>

                    <Tab eventKey="food-diet" title="Food &amp; Diet">
                      <h1 className="mb-4">Food &amp; Diet</h1>

                      <p className="lead">Your Estimated Impact (based on U.S. state averages)</p>

                      <p className="lead">What Have You Done to Change Your Impact?</p>

                      <p>We include meat in our diet &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="At most meals" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Most days, but not every meal" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Rarely" type={type} id={`inline-${type}-3`} />
                            <Form.Check inline label="Never" type={type} id={`inline-${type}-4`} />
                          </div>
                        ))}
                      </Form>

                      <p>We eat organic food &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Most of the time" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Sometimes" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Never or rarely" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <ul style={{textAlign: `left`, margin: `0.2rem`}}>
                                  <li>If you eat red meat or fish most every day, select "At most meals".</li>
                                  <li>If you eat a variety of meats, but don't avoid red meats or fish, select "Most days".</li>
                                  <li>If the meat you eat is primarily poultry or you don't eat much meat at all, select "Rarely".</li>
                                  <li>If you have a vegetarian diet that includes eggs and milk, select "Rarely".</li>
                                  <li>Strict vegetarians and vegans should select "Never".</li>
                                  <li>Consider both home meals and dining out.</li>
                                  <li>Eating meat raised locally, grass-fed or that you hunt generates fewer emissions.</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 1</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <ul style={{textAlign: `left`, margin: `0.2rem`}}>
                                  <li>Not all food can be obtained organically and that which can may not be available all of the time.</li>
                                  <li>If you seek out organic food as your first choice for all of your food choices, select "Most of the time".</li>
                                  <li>If you buy organic on some products, but pass on others, select "Sometimes".</li>
                                  <li>If you do not consider organic food choices or frequently pass on purchasing organic, select "Never or rarely".</li>
                                  <li>Consider both grocery shopping and dining out.</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 2</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar>
                        <Button size="lg" style={{position: `relative`, float: `right`}}>Continue</Button>
                      </ButtonToolbar>
                    </Tab>

                    <Tab eventKey="recycling-waste" title="Recycling &amp; Waste">
                      <h1 className="mb-4">Recycling &amp; Waste</h1>

                      <p className="lead">Your Estimated Impact (based on U.S. averages)</p>

                      <p className="lead">What Have You Done to Change Your Impact?</p>

                      <p>We recycle &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="All materials locally recyclable" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Some of our waste" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Not much" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <p>We compost food scraps and yard trimmings &hellip;</p>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className="mb-3">
                            <Form.Check inline label="Whenever possible" type={type} id={`inline-${type}-1`} />
                            <Form.Check inline label="Sometimes" type={type} id={`inline-${type}-2`} />
                            <Form.Check inline label="Rarely" type={type} id={`inline-${type}-3`} />
                          </div>
                        ))}
                      </Form>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <ul style={{textAlign: `left`, margin: `0.3rem`}}>
                                  <li>If you typically recycle everything you know is recyclable, select "All materials".</li>
                                  <li>If you don't make it a habit to recycle at least some of your waste, select "Not much".</li>
                                  <li>Anything in between can be considered "Some of our waste".</li>
                                  <li>Your county or city solid waste management division may have a list of locally recyclable materials based on the type of material and the numeric code for plastic containers.</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 1</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>

                      <ButtonToolbar className="mb-3">
                        {['right'].map(placement => (
                          <OverlayTrigger
                            key={ placement }
                            placement={ placement }
                            overlay={
                              <Tooltip id={`tooltip-${ placement }`}>
                                <ul style={{textAlign: `left`, margin: `0.3rem`}}>
                                  <li>If you divert most all of your food waste by composting it, you avoid a significant portion of your household's solid waste and you should select "Whenever possible".</li>
                                  <li>If you have a compost pile or bin and use it from time to time, select "Sometimes".</li>
                                  <li>If you do not compost, select "Rarely".</li>
                                </ul>
                              </Tooltip>
                            }
                          >
                            <Button size="sm">More Info 2</Button>
                          </OverlayTrigger>
                        ))}
                      </ButtonToolbar>
                    </Tab>

                    <Tab eventKey="results" title="Results">
                      <h1 className="mb-4">Results</h1>
                    </Tab>
                  </Tabs>
                </div>
              </Card>

              <p>
                <a href="https://support.nature.org/site/SPageServer/?pagename=carboncalculator_xx" target="_blank" rel="noopener noreferrer">Join our online community</a> to be part of our global community working together to help slow climate change.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonCalculator
